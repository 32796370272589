import { config } from "@atlas/app-config";
import { cn } from "@atlas/ui/helpers";
import { Icon } from "@atlas/ui/Icon";

export const AtlasLogoWithOrgLogo = ({ orgId }: { orgId?: string | null }) => {
  return (
    <div className="ml-3.5 flex items-center justify-center gap-4">
      {orgId ? (
        <>
          <Icon icon="logo" className="!m-0 h-[50px] w-[120px] shrink-0" />
          <span>+</span>
          <img
            alt={orgId || "logo"}
            src={`${config.org_pictures_url}/${orgId}/logo.png`}
            className={cn("mb-0 h-24 shrink-0", {
              "h-[4.5rem] w-36": orgId === "nj_stfa",
              "!h-28": orgId === "nj_ocpo",
              "!h-16": orgId === "tmp",
            })}
          />
        </>
      ) : (
        <Icon icon="logo" className="mt-20 h-[50px] w-[120px] shrink-0 lg:mt-5" />
      )}
    </div>
  );
};
