import { GetServerSidePropsContext } from "next";

import { userRouter } from "@atlas/lib/authentication/user";
import { statsig } from "@atlas/service/statsig";

import { LoginPage } from "../pods/auth/login/pages/LoginPage";

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
  // Example : njsbpa.atlas.net redirects to app.atlas.net/signup/njspba
  const hostParts = context.req.headers.host?.split(".") || [];
  if (hostParts.length > 2 && hostParts[0] !== "app") {
    const org = hostParts[0];
    const rootHost = hostParts.slice(1).join(".");
    const protocol = context.req.headers["x-forwarded-proto"]?.includes("https") ? "https" : "http";
    let destination = `${protocol}://app.${rootHost}/signup/${org}`;
    if (Object.keys(context.query).length > 0)
      destination += "?" + new URLSearchParams(context.query as any).toString();
    return { redirect: { destination, permanent: true } };
  }

  // Re-route if already logged in
  await userRouter(context);

  const disableSignups = await statsig.checkGate(
    { account_id: "" },
    "disable_signups_for_waitlist",
  );

  const logoutMessage = context.req.headers.referer?.includes("/onboard")
    ? "Finish your enrollment later by logging back in."
    : null;

  return { props: { title: "Login", logoutMessage, disableSignups } };
};

export default LoginPage;
