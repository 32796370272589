import { useRouter } from "next/router";
import { FormEvent, useRef, useState } from "react";

import { useAnalytics } from "@atlas/app/context/analytics";
import { config } from "@atlas/app-config";
import { useMountEffect } from "@atlas/hooks/useMountEffect";
import { useRouterRefreshSSR } from "@atlas/hooks/useRouterRefreshSSR";
import { request } from "@atlas/lib/request";
import { toast } from "@atlas/lib/toast";
import { Button } from "@atlas/ui/Button";
import { EmailInput } from "@atlas/ui/EmailInput";
import { cn } from "@atlas/ui/helpers";
import { PasswordInput } from "@atlas/ui/PasswordInput";

import { AtlasLogoWithOrgLogo } from "../../components/AtlasLogoWithOrgLogo";

export const LoginPage = ({
  logoutMessage,
  orgId,
  disableSignups,
}: {
  logoutMessage?: string;
  orgId: string | undefined | null;
  disableSignups?: boolean;
}) => {
  const { logEvent } = useAnalytics();
  const [loading, setLoading] = useState(false);
  const form = useRef<HTMLFormElement>(null);
  const emailInput = useRef<HTMLInputElement>(null);
  const router = useRouter();
  const defaultEmail = router.query.email ? decodeURIComponent(router.query.email as string) : null;
  const redirect = router.query.redirect as string;
  useRouterRefreshSSR();

  const login = async (event: FormEvent) => {
    event.preventDefault();
    const elements = form.current?.elements as any;

    const json = {
      email: elements.email.value,
      password: elements.password.value,
    };

    if (loading) return;
    setLoading(true); // Set loading manually to revent-double logins
    const res = await request(`${config.old_api_url}/user/session/login`, {
      method: "POST",
      json,
    });

    if (res?.complete) {
      logEvent("User Logged In", {
        source: "password",
      });

      // if there is a redirect query param, go there
      if (redirect) {
        window.location.href = redirect;
      } else {
        window.location.href = config.user_home_path;
      }
    } else {
      setLoading(false);
    }
  };

  const resetPassword = async (e: FormEvent) => {
    e.preventDefault();

    const email = (form.current!.elements as any).email.value;
    if (!email.length) {
      router.push("/forgot-password");
    } else {
      if (!emailInput.current?.reportValidity()) {
        return;
      }
      const res = await request(`${config.old_api_url}/user/password/request`, {
        method: "POST",
        json: { email },
      });

      if (res?.complete) {
        toast.success(
          "If an account exists for this email, then a reset email was sent. Please check your email inbox.",
          { autoClose: false },
        );
      }
    }
  };

  useMountEffect(() => {
    if (logoutMessage) toast.info(logoutMessage);
  });

  const ssoRedirectUrl = `${config.old_api_url}/auth/google/?type=login${redirect ? `&redirect=${redirect}` : ""}${
    orgId ? `&orgId=${orgId}` : ""
  }`;

  return (
    <div className="flex min-h-screen flex-col items-center justify-center p-4 md:p-0">
      <div className="flex w-full flex-col items-center justify-center gap-8">
        <AtlasLogoWithOrgLogo orgId={orgId} />
        <form
          onSubmit={login}
          ref={form}
          className="flex w-full max-w-sm flex-col items-center gap-4"
        >
          <EmailInput
            required
            autoComplete="email"
            id="email"
            data-attr="email"
            defaultValue={defaultEmail ?? ""}
            ref={emailInput}
            className="w-full"
          />
          <PasswordInput
            required
            autoComplete="current-password"
            id="password"
            data-attr="password"
            defaultValue=""
            className="w-full"
          />
          <Button variant="tertiary" className="" onClick={resetPassword}>
            Forgot Password?
          </Button>
          <Button className="w-full" formAction="submit" loading={loading} data-attr="log-in">
            Log in
          </Button>
          <div className="my-4 flex w-full items-center gap-2">
            <div className="bg-gray-darker h-px flex-1"></div>
            <p className="text-sm">OR</p>
            <div className="bg-gray-darker h-px flex-1"></div>
          </div>
          <Button
            onClick={() => {
              const email = emailInput.current?.value;
              const emailRedirect = `/login/request/${orgId ?? ""}${
                email?.length ? `?email=${email}` : ""
              }`;
              router.push(emailRedirect);
            }}
            iconLeft="Mail"
            variant="secondary"
            className={cn("w-full")}
            type="button"
          >
            Log in with Email
          </Button>
          <Button
            href={ssoRedirectUrl}
            iconLeft="LogoGoogle"
            variant="secondary"
            className={cn("w-full")}
          >
            Log in with Google
          </Button>
          <Button
            variant="secondary"
            className={cn("w-full")}
            iconLeft="Lock"
            href="/login/sso"
            type="button"
          >
            Log in with SSO
          </Button>
        </form>
        {!disableSignups ? (
          <Button
            variant="tertiary"
            className={cn("mt-8")}
            href={`/signup${orgId ? `/${orgId}` : ""}`}
          >
            Sign up instead
          </Button>
        ) : (
          <Button
            variant="tertiary"
            className={cn("mt-8")}
            href={"/waitlist"}
            data-attr="join-waitlist"
          >
            Join Waitlist
          </Button>
        )}
      </div>
    </div>
  );
};
