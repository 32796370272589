import { cva, type VariantProps } from "class-variance-authority";
import React, { useState } from "react";

import { cn } from "./helpers";
import { Icon } from "./Icon";
import { TextInput, TextInputProps } from "./TextInput";

const passwordInputVariants = cva("relative w-full", {
  variants: {
    variant: {
      default: "text-black",
      dark: "bg-gray-800 text-primary-lighter",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

export interface PasswordInputProps
  extends Omit<TextInputProps, "type" | "variant">,
    VariantProps<typeof passwordInputVariants> {}

export const PasswordInput = React.forwardRef<HTMLInputElement, PasswordInputProps>(
  ({ className, variant, label = "Password", placeholder = "***********", ...props }, ref) => {
    const [passwordShown, setPasswordShown] = useState(false);

    const togglePasswordVisibility = (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      setPasswordShown(!passwordShown);
    };

    return (
      <div className={cn(passwordInputVariants({ variant, className }))}>
        <div className="relative">
          <TextInput
            {...props}
            ref={ref}
            type={passwordShown ? "text" : "password"}
            label={label}
            placeholder={placeholder}
            minLength={7}
            className="text-xl font-normal"
          />
          <div
            onClick={togglePasswordVisibility}
            className={cn(
              "absolute right-4 top-[67%] z-10 -translate-y-1/2 cursor-pointer",
              passwordShown ? "text-primary-lighter" : "opacity-50 hover:opacity-80",
            )}
          >
            <Icon icon={passwordShown ? "Show" : "Hide"} />
          </div>
        </div>
      </div>
    );
  },
);

PasswordInput.displayName = "PasswordInput";
